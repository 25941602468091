<template>
  <div class="general">
    <div class="contenedor">
      <h1 class="tituloGestiones" style="color: var(--grisOscuroPrimario)">
        Servicios Municipales

        <div class="linea"></div>
      </h1>
      <!-- MOBILE -->
      <div class="carousel-mobile">
        <div class="cardGestionesMobile">
          <router-link to="/tramites-servicios/pagar-tasas" class="link">
            <img
              src="https://d24g0foqntlzx0.cloudfront.net/icons/iconsDB/Tasas+e+impuestos.svg"
              alt=""
            />
            <h5 style="color: var(--verdePrincipal)">Pagar Tasas</h5>
          </router-link>
        </div>
        <div v-for="(item, index) in gestionesMobile" :key="index">
          <div class="cardGestionesMobile">
            <router-link
              :to="`/tramites-servicios/guia-tramites/${item.title}/${item.id}`"
              class="link"
            >
              <img :src="item.icon" alt="" />
              <h5 style="color: var(--verdePrincipal)">{{ item.title }}</h5>
            </router-link>
          </div>
        </div>
      </div>

      <!-- WEB -->
      <div v-if="gestiones.length > 0">
        <div class="gridcontainer">
          <div class="cardGestiones">
            <router-link to="/tramites-servicios/pagar-tasas" class="link">
              <div class="card-body">
                <div>
                  <img
                    src="https://d24g0foqntlzx0.cloudfront.net/icons/iconsDB/Tasas+e+impuestos.svg"
                    alt=""
                  />
                </div>
                <div class="titulo">
                  <h5>Pagar Tasas</h5>
                </div>
              </div>
            </router-link>
          </div>

          <div
            v-for="(item, index) in gestiones"
            :key="index"
            class="cardGestiones"
          >
            <router-link
              :to="`/tramites-servicios/guia-tramites/${item.title}/${item.id}`"
              class="link"
            >
              <div class="card-body">
                <div><img :src="item.icon" alt="" /></div>
                <div class="titulo">
                  <h5>
                    {{ item.title }}
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="masGestiones">
        <router-link :to="'/tramites-servicios/guia-tramites'" class="link">
          <p style="color: var(--grisOscuroPrimario)">
            Ingresá aquí para ver todas las gestiones
          </p>
        </router-link>
      </div>
      <div class="masMobile">
        <router-link :to="'/tramites-servicios/guia-tramites'" class="link">
          <div class="internomasMobile">
            <h6>Ver Guía de trámites completa</h6>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
// import { Carousel, Slide } from "vue3-carousel";
// import { BASE_URL } from "@/env";
import { BASE_URL } from "@/env";
import axios from "axios";

export default {
  name: "TarjetasComponent",
  components: {
    // Carousel,
    // Slide,
    // Navigation,
  },
  created() {
    this.getCategorias();
  },
  data() {
    return {
      // uno: 0,
      // dos: 1,
      gestiones: [
        // {
        //   titulo: "Pagar Tasas",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/pagarTasas_brlaee.svg",
        // },
        // {
        //   titulo: "Habilitaciones Comerciales",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700311/habilitacion_c4xviq.svg",
        // },
        // {
        //   titulo: "Licencias de Conducir",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700311/licenciaCondu_fyphnr.svg",
        // },
        // {
        //   titulo: "Obras Privadas",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/obrasPri_tdk8re.svg",
        // },
        // {
        //   titulo: "Catastro Municipal",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/catastroMuni_qh7ej8.svg",
        // },
        // {
        //   titulo: "Registro Civil",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/registro_dikezr.svg",
        // },
        // {
        //   titulo: "Plazas y Espacios Verdes",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/plazaEspacio_p3qe2y.svg",
        // },
        // {
        //   titulo: "Calles y Veredas",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/callesYvere_khigwt.svg",
        // },
      ],
      gestionesMobile: [],
      hover: false,
    };
  },
  methods: {
    getCategorias() {
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient.get("/oficina/categories/categories").then((response) => {
        // console.log(response.data, "estoy aca");
        let categorias = response.data.Categories.Procedures;
        let obras = response.data.Categories.workArray;
        let servicios = response.data.Categories.Services;
        // let impuestos = response.data.Categories.taxArray;

        // this.impuestos[0].title = "Tasas e Impuestos";
        // this.impuestos[0].description =
        // // "Consultá y pagá, inscribíte, hacé presentaciones, solicitá informes y más. ¡Tu contribución es clave para seguir mejorando nuestra comunidad!.";
        // let obras = response.data.Categories.workArray;
        // this.obras[0].title = "Catastro y Obras Privadas";
        // this.obras[0].description =
        // "Realizá consultas y gestiones en el Catastro Municipal, como también permisos y trámites para obras privadas. Todo fácil, rápido y en un solo lugar.";
        let transito = response.data.Categories.licencesArray;

        this.gestiones = [
          categorias[0],
          categorias[1],
          transito[0],
          servicios[0],
          servicios[5],
          obras[0],
          obras[1],
        ];
        this.gestionesMobile = [
          categorias[0],
          categorias[1],
          transito[0],
          servicios[0],
          servicios[5],
        ];
        // console.log(this.gestiones, "asoy las getionews");
        // for (let index = 0; index < 4; index++) {
        //   this.gestiones.push(this.categorias[index]);
        // }
        // this.loading = false;
      });
      //.catch((error) => {
      //console.log(error);
      //});
    },
    // setClass() {
    //   this.hover = true;
    // },
    // siguiente() {
    //   if (this.uno <= 6) {
    //     this.uno = this.uno + 2;
    //     if (this.dos <= 5) {
    //       this.dos = this.dos + 2;
    //     } else {
    //       this.dos = 5;
    //     }
    //   } else {
    //     this.uno = 6;
    //   }
    // },
    // atras() {
    //   this.uno = this.uno - 2;
    //   this.dos = this.dos - 2;
    // },
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.general {
  width: 963px;
  height: 403px;
  margin: auto;
  background: var(--verdeDegrade);
  padding-bottom: 6px;
  border-radius: 50px 50px 18px 18px;
  margin-bottom: 60px;
}
.contenedor {
  position: relative;
  background: #fff;
  color: rgba(255, 255, 255, 0.432);
  width: 100%;
  height: 100%;
  padding: 34px 0px;
  background-image: url("./../../assets/images/Frame.svg");
  /* opacity: 0.2; */
  background-repeat: space;
  background-position: center;
  text-align: center;
  overflow: hidden;
  border-radius: 18px;

  /* filter: blur(25px); */
}
.tituloGestiones {
  /* color: #757070; */
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
}
.top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 8%;
  background: linear-gradient(
    90deg,
    #00c3a8 0.18%,
    #ffb900 52.79%,
    #ff2745 107.64%
  );
  text-align: center;
  /* padding: 2%; */
}
.top h6 {
  visibility: hidden;
}
/* .buscador {
  position: absolute;
  width: 30%;
  height: 8%;
  background: #00c3a8;
  top: 0;
  right: 0;
  border-bottom-left-radius: 50px;
  text-align: left;
  padding-left: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
} */
label {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
input {
  background: none;
  border-style: none;
  margin-left: 0.5rem;
  margin-right: 1rem;
  width: 60%;
}
.gridcontainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 829px;
  gap: 12px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
h5 {
  color: #3e3e3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 3%;
}
.cardGestiones {
  background: #fff;
  border-radius: 12px;
  border: 1px solid var(--bordecard);
  height: 81.9px;
  width: 197px;
  margin: auto;
}
.link {
  text-decoration: none;
  color: inherit;
}
.card-body {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border-radius: 11px;
  height: 100%;
  width: 100%;
  text-align: left;
  background: white;
  justify-content: center;
}
.card-body img {
  width: 45px;
  height: 45px;
}
.cardGestiones:hover {
  background: var(--bordeDegradeCompleto);
  border: 0.237px solid var(--bordecard);
  padding: 2px;
  border: none;
  /* width: 110%; */
  /* position: relative; */
}
.titulo {
  width: 60%;
}
.masGestiones {
  border-radius: 30.356px;
  background: #f5f5f5;
  height: 42.6px;
  width: 332px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* padding-top: 1%; */
}
.masGestiones p {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.masMobile {
  display: none;
}
/* .lineab {
  position: absolute;
  height: 6px;
  width: 100%;
  background: linear-gradient(
    270deg,
    #e52320 9.64%,
    #ffcc03 55.98%,
    #019939 87.68%
  );

  bottom: 0;
} */

.linea {
  height: 6px;
  background: var(--tituloDegrade);
  width: 466px;
  margin: auto;
  /* margin-top: -6px; */
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.carousel-mobile {
  display: none;
}
@media (max-width: 1360px) {
  .general {
    width: 70%;
    height: auto;
  }
  .gridcontainer {
    display: grid;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 85%;
    gap: 12px;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 35px;
  }
}
@media (max-width: 770px) {
  .cardGestionesMobile {
    width: 93px;
    height: 71px;
    background: #fff;
    padding: 9px 12px;
    margin: auto;
    border-radius: 7.494px;
    border: 1px solid var(--verdePrincipal);
  }
  .cardGestionesMobile img {
    height: 30px;
    width: 30pox;
  }
  .tituloGestiones {
    font-size: 17px;
    font-weight: 700;
    line-height: normal;
  }

  .linea {
    width: 190px;
    height: 3.5px;
  }
  .general {
    width: 316px;
    height: 281px;
    margin: auto;
    background: var(--bordeDegradeCompleto);
    padding-bottom: 2px;
    border-radius: 13px;
    margin-bottom: 20px;
  }
  .contenedor {
    position: relative;
    /* background: red; */
    color: rgba(255, 255, 255, 0.432);
    width: 100%;
    height: 100%;
    padding: 20px 0px;
    background-image: url("./../../assets/images/Frame.svg");
    background-repeat: space;
    background-position: center;
    text-align: center;
    overflow: hidden;
    border-radius: 11px;
  }
  .gridcontainer {
    display: none;
  }
  .masGestiones {
    display: none;
  }
  .masMobile {
    display: block;
    width: 214px;
    height: 29px;
    border-radius: 10px;
    margin: auto;
    background: var(--verdeDegrade);
    padding: 1px;
    cursor: pointer;
  }
  .internomasMobile {
    height: 100%;
    width: 100%;
    border-radius: 9px;
    background: #fff;
    padding-top: 7px;
  }
  .masMobile h6 {
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.36px;
  }
  .carousel-mobile {
    display: grid;
    grid-template-columns: repeat(3, 93.6px);
    width: 313px;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    gap: 5px;
    position: relative;
    /* background: rebeccapurple; */
  }
  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel__slide {
    padding: 1% 1%;
    background: #fff;
    min-height: 61px;
    min-width: 146px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0.5rem;
  }
  .cardGestionesMobile h5 {
    font-size: 10px;
  }
}
</style>
