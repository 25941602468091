<template>
  <div class="contenedor-home">
    <div class="seccion-video">
      <!-- Video del home de la web -->
      <video
        src="https://d24g0foqntlzx0.cloudfront.net/icons/videos/videoHome.webm"
        autoplay
        muted
        playsinline
        class="video"
      ></video>
      <!-- Sección clima dentro del video del home -->
      <div
        class="clima"
        :style="{ left: anchoVentana > 1360 ? '84px' : `${leftmargin}px` }"
      >
        <img :src="icono" alt="" class="img" />
        <div class="texto-clima">
          <h1 style="color: #fff">{{ temperatura }}°</h1>
          <h6 style="color: #fff">{{ tiempo }}</h6>
        </div>

        <h6 style="color: #fff" class="fecha">{{ fecha }}</h6>
      </div>
    </div>
    <!-- TarjetasComponent es el cuadro de las gestiones más usuales de la web o las que más ingresan los vecinos -->
    <TarjetasComponent />
    <!-- ImageComponent siempre tenía alguna imagen, ultimamente tiene videos así que sería ideal cambiarle el nombre a MultimediaComponent -->
    <ImagenComponent />
    <!-- PromocionesComponent siempre se separa en dos imagenes para poder dar a conocer o informar liquidaciones, fechas festivas, información de la Oficina Virtual -->
    <PromocionesComponent />
    <!-- CardEco es para demostrar que la municipalidad de Luque está enfocada en proteger el planeta con las tecnologías -->
    <CardEcoComponent />

    <!-- En esta parte está el titulo de las noticias dentro del home, muestra un carrousel que nosotros importamos y lo único que hacemos es usar la ruta de noticias para mostrar ahí -->
    <TituloComponent titulo="ÚLTIMAS NOVEDADES" />

    <div class="carouselNoticias">
      <CarouselComponent />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TarjetasComponent from "@/components/Buscador/TarjetasComponent.vue";
import ImagenComponent from "@/components/Imagen/ImagenComponent.vue";
import PromocionesComponent from "@/components/Promociones/PromocionesComponent.vue";
import CardEcoComponent from "@/components/Ecologico/CardEcoComponent.vue";
import CarouselComponent from "@/components/Carousel/CarouselComponent.vue";
import TituloComponent from "@/components/Reutilizar/TituloComponent.vue";
import { useHead } from "@vueuse/head";

useHead({
  title: "Inicio - Municipalidad de Luque",
  meta: [
    {
      name: "description",
      content:
        "Bienvenidos al sitio oficial de la Municipalidad de Luque. Encuentra información sobre trámites, servicios, noticias y eventos para nuestra comunidad.",
    },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
  ],
});

export default {
  name: "HomeView",
  components: {
    TarjetasComponent,
    ImagenComponent,
    PromocionesComponent,
    CardEcoComponent,
    CarouselComponent,
    TituloComponent,
  },
  data() {
    return {
      temperatura: "",
      tiempo: "",
      icono: "",
      fecha: "",
      anchoVentana: "",
      leftmargin: "",
    };
  },
  methods: {
    getTiempo() {
      axios
        .get(
          "https://api.tutiempo.net/json/?lan=es&apid=a5GX44aXXaahzCV&ll=-31.6476686,-63.3444"
        )
        .then((response) => {
          // console.log(response);
          this.temperatura = response.data.hour_hour.hour1.temperature;
          this.tiempo = response.data.hour_hour.hour1.text;
          let icon = response.data.hour_hour.hour1.icon;
          // let dia = new Date();
          // this.dia = dia.getDate();
          // this.getDia(dia.getDay());
          // this.getMes(dia.getMonth());
          // this.text = asd.split(" ");
          // this.text = this.text[0].toUpperCase();
          // this.text = asd.toUpperCase();

          this.icono = `https://v5i.tutiempo.net/wi/02/30/${icon}.png`;
        });
      // esto es para ver si el icono que ponga diseño funciona más adelante!
      //const iconMap = {
      //1: "https://res.cloudinary.com/dueylp9hx/image/upload/v1736970818/sol-y-luna_vuohgy.png",
      //};

      //this.icono =
      //iconMap[icon] || `https://v5i.tutiempo.net/wi/02/30/${icon}.png`;
      //});
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.getTiempo();
    let dia = new Date().getDate();
    let mes = new Date().getMonth() + 1;
    this.fecha = `${dia}/${mes}`;
    this.anchoVentana = window.innerWidth;
    this.leftmargin = (this.anchoVentana - 134) / 2;
    // console.log(this.leftmargin, "soy el ancho de la ventana");
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.video {
  width: 100%;
  border-bottom-right-radius: 53.12px;
}
.contenedor-home {
  margin-top: 0px;
  padding-top: 100px;
}
.clima {
  z-index: 3;
  min-height: 250px;
  width: 100px;
  background: rgba(0, 49, 6, 0.3);
  border-radius: 50px;
  position: absolute;
  top: 100px;
  /* // left: 84px; */
  color: #fff;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.clima h1 {
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  color: #fff;
}
.clima h6 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
}
.texto-clima {
  margin-top: 16px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.seccion-video {
  width: 100%;
  background: var(--bordeDegradeCompleto);
  border-bottom-right-radius: 53.12px;
  margin-bottom: 60px;
  position: relative;
}
.izquierdo {
  width: 33vw;
  height: 1px;
  background: #4b4a49;
  margin-bottom: 1%;
}

.derecho {
  height: 1px;
  width: 33vw;
  background: #4b4a49;
  margin-bottom: 1%;
}
.carouselNoticias {
  align-items: center;
  margin-top: 60px;
}
.masGestiones {
  display: none;
}
.img {
  height: 48px;
  width: auto;
}
@media (max-width: 1360px) {
  .clima {
    top: 50px;
    min-height: 200px;
  }
  .video {
    height: auto;
  }
}
@media (max-width: 770px) {
  .seccion-video {
    width: 100%;
    background: none;
    border-bottom-right-radius: 25px;
    margin-bottom: 20px;
    position: relative;
    /* height: 248px; */
    /* padding-top: 56px; */
  }

  .video {
    width: 100%;
    border-bottom-right-radius: 25px;
    position: relative;
  }
  .contenedor-home {
    width: 100%;
    padding-top: 55px;
    border-bottom-right-radius: 25px;
  }
  /* .clima {
    display: none;
  } */
  .clima {
    z-index: 3;
    min-height: 52px;
    width: 134px;
    background: rgba(0, 49, 6, 0.3);
    border-radius: 27px;
    position: absolute;
    padding: 7px 15px;
    /* bottom: 21px; */
    /* left: 0px; */
    margin: auto;
    color: #fff;
    /* padding-top: 30px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .clima h1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    color: #fff;
  }
  .texto-clima {
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
  }
  .img {
    height: 38px;
    width: auto;
  }
  .texto-clima h6 {
    font-size: 8px;
  }
  .fecha {
    display: none;
  }
  .carouselNoticias {
    margin-top: 20px;
  }
  /* .clima {
    display: none;
  } */
}
</style>
