<template>
  <NavTopComponent />
  <router-view />
  <!--<FiestaPiemontesaComponent
    v-if="$route.path !== '/ciudad/fiesta-nacional-familia-piemontesa'"
  />-->
  <FooterComponent />
</template>
<script>
import NavTopComponent from "./components/Nav/NavTopComponent.vue";
import FooterComponent from "./components/Footer/FooterComponent.vue";
// import FiestaPiemontesaComponent from "./components/Fiesta/FiestaPiemontesaPubliComponent.vue";

export default {
  components: {
    NavTopComponent,
    FooterComponent,
    // FiestaPiemontesaComponent,
  },
};
</script>
<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--texto);
  }
  width: 100%;
  --bordecard: #9b9a9a31;
  --verdePrincipal: #00c3a8;
  --verdeDegrade: linear-gradient(
    106deg,
    #00c3a8 0%,
    #ffb900 52%,
    #ff2745 100%
  );
  --amarilloDegrade: linear-gradient(to left, #00c3a8 0%, #ffb900 101.17%);
  --grisMedio: #9b9a9a;
  --texto: #3e3e3e;
  --grisOscuroPrimario: #757575;
  --bordeDegradeCompleto: linear-gradient(
    90deg,
    #3fc2f5 0%,
    #7ac943 33%,
    #ff931e 67%,
    #ff1d25 100%
  );
  --tituloDegrade: linear-gradient(
    to right,
    #ff6928,
    #ff8219,
    #ff9a08,
    #ecab00,
    #d7b909,
    #c2c627,
    #96ca4b,
    #6ccb6d,
    #3fc88d,
    #00c3a8
  );
}
/*
@media (max-width: 500px) {
  #app {
    width: 100vw;
    height: auto;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
} */
/* nav {
  padding: 30px;
} */

/* nav a {
  font-weight: bold;
  color: #2c3e50;
} */

/* nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
